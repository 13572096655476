import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { InvitationService } from '../../services/invitation.service';
import { CompanyService } from '../../../company/services/company.service';
import { RoleService } from '../../../control-panel/services/role.service';
import { Company } from '../../../../models/company';
import { Role } from '../../../../models/role';
import { LoginService } from '../../../auth/services/login.service';
import { Router } from '@angular/router';
import { ConfirmModalComponent } from '../../../ui/components/confirm-modal/confirm-modal.component';
import { Invitation } from '../../../../models/invitation';
import { environment } from '../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SendInvitationModalComponent } from '../send-invitation-modal/send-invitation-modal.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { lastValueFrom } from 'rxjs';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { DefaultSortingDataAccessor } from '../../../../helpers/DefaultSortingDataAccessor';
import { DefaultFilterPredicate } from '../../../../helpers/DefaultFilterPredicate';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';
import { Clipboard } from '@angular/cdk/clipboard';
import { InvitationModalComponent } from '../invitation-modal/invitation-modal.component';
import { SnackService } from '../../../ui/services/snack.service';
import { DialogService } from '../../../ui/services/dialog.service';

@Component({
    selector: 'xkey-invitation',
    templateUrl: './invitation.component.html',
    styleUrls: ['./invitation.component.scss'],
    animations: [AnimationTriggerMetadata()],
    standalone: false
})
export class InvitationComponent implements OnInit {
  invitations: Invitation[] = [];
  dataSource = new MatTableDataSource<Invitation>([]);
  displayedColumnsDesktop = ['code', 'role', 'email', 'used', 'actions'];
  displayedColumnsMobile = ['arrow', 'code'];
  expandedElement: Invitation | null;

  @Input() company: Company;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private invitationService: InvitationService,
    private companyService: CompanyService,
    private roleService: RoleService,
    private router: Router,
    private snackService: SnackService,
    public loginService: LoginService,
    public handsetObserverService: HandsetObserverService,
    private translate: TranslateService,
    private clipboard: Clipboard,
    private dialogService: DialogService
  ) {}

  async ngOnInit() {
    await this.getInvitations();
  }

  async openCreateModifyModal(invitation?: Invitation) {
    const roles: Role[] = await lastValueFrom(this.roleService.getRoles());
    const companies: Company[] = await lastValueFrom(this.companyService.getCompanies());

    this.dialogService.showDialog(
      InvitationModalComponent,
      {
        invitation: invitation ? { ...invitation } : new Invitation(),
        roles: roles.filter((role) => role.canBeInvited),
        companies: companies,
        presetCompany: this.company ?? undefined,
        email: invitation?.email ?? undefined,
      },
      () => {
        this.getInvitations();
      }
    );
  }

  openDeleteModal(invitationId: number) {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-invitation', title: this.translate.instant('invitation.deleteInvitation') }, () => {
      this.invitationService.deleteInvitation(invitationId).subscribe(async () => {
        await this.getInvitations();
      });
    });
  }

  async getInvitations() {
    if (this.company) {
      this.invitations = await lastValueFrom(this.companyService.getCompanyInvitations(this.company.id));
    } else {
      this.invitations = await lastValueFrom(this.invitationService.getInvitations());
    }

    this.dataSource = new MatTableDataSource(this.invitations);
    this.dataSource.filter = '';
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = DefaultFilterPredicate();
    this.dataSource.sortingDataAccessor = DefaultSortingDataAccessor();
    this.dataSource.sort = this.sort;
  }

  async openRole(role: any) {
    if (!role || (role && !this.loginService.userHasPermissions('Role_Read'))) {
      return;
    }

    await this.router.navigate(['/roles/' + role.id]);
  }

  copyLink(row) {
    this.clipboard.copy(environment.domain + '/register/' + row.code);
    this.snackService.showMessage('invitation.copiedToClipBoard');
  }

  sendLink(invitation: Invitation) {
    this.dialogService.showDialog(SendInvitationModalComponent, { invitation }, () => {});
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
