import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SnackService } from '../../../ui/services/snack.service';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'xkey-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit {
  user: any;
  form: FormGroup;
  submitted = false;
  protected readonly environment = environment;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private snackService: SnackService,
    private formBuilder: FormBuilder
  ) {}

  get f() {
    return this.form.controls;
  }

  async ngOnInit() {
    if (this.loginService.getToken()) {
      await this.router.navigate(['/etusivu']);
    }

    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  login() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loginService.login({ email: this.f.email.value, password: this.f.password.value }).subscribe({
      next: (response) => {
        this.user = response;
        void this.router.navigate(['/etusivu']);
      },
      error: (error) => {
        console.error(error);

        if (error.status === 401) {
          this.snackService.showMessage('login.wrongUserOrPassword');
        } else if (error.status === 403) {
          this.snackService.showMessage('login.userNotActive');
        }
      },
    });
  }
}
