import { Component, OnInit } from '@angular/core';
import { ResidentService } from '../../services/resident.service';
import { ApartmentService } from '../../../apartment/services/apartment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { OpenersService } from '../../../ui/services/openers.service';
import { LoginService } from '../../../auth/services/login.service';
import { Resident } from '../../../../models/resident';
import { ResidentModalComponent } from '../resident-modal/resident-modal.component';
import { ConfirmModalComponent } from '../../../ui/components/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { DialogService } from '../../../ui/services/dialog.service';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { CompanyService } from '../../../company/services/company.service';

@Component({
    selector: 'xkey-resident-single',
    templateUrl: './resident-single.component.html',
    styleUrls: ['./resident-single.component.scss'],
    standalone: false
})
export class ResidentSingleComponent implements OnInit {
  selectedResident: Resident;

  constructor(
    private residentService: ResidentService,
    private apartmentService: ApartmentService,
    private companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private location: Location,
    public openers: OpenersService,
    public loginService: LoginService,
    private dialogService: DialogService,
    public handsetObserverService: HandsetObserverService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.setupComponent(params['id']);
    });
  }

  setupComponent(id) {
    this.residentService.getResident(id).subscribe((resident: Resident) => {
      this.selectedResident = resident;
    });
  }

  async openUpdateModal(resident: Resident) {
    const apartments = await lastValueFrom(this.apartmentService.getApartments());
    const owners = await lastValueFrom(this.companyService.getCompanies());

    this.dialogService.showDialog(ResidentModalComponent, { resident: { ...resident }, apartments, owners }, () => {
      this.residentService.getResident(this.selectedResident.id).subscribe((updatedResident: Resident) => {
        this.selectedResident = updatedResident;
      });
    });
  }

  openDeleteModal(residentId: number) {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-resident', title: this.translate.instant('resident.deleteResident') }, () => {
      this.residentService.deleteResident(residentId).subscribe(() => {
        this.location.back();
      });
    });
  }
}
