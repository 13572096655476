import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Announcement } from '../../../../models/announcement';
import { AnnouncementService } from '../../services/announcement.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';

@Component({
    selector: 'xkey-announcement-modal',
    templateUrl: './announcement-modal.component.html',
    styleUrls: ['./announcement-modal.component.scss'],
    standalone: false
})
export class AnnouncementModalComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private announcementService: AnnouncementService,
    private snackService: SnackService,
    public dialogRef: MatDialogRef<AnnouncementModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { announcement: Announcement }
  ) {}

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      title: [this.data.announcement?.title, Validators.required],
      message: [this.data.announcement?.message, Validators.required],
    });
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const { title, message } = this.f;
    this.saveAnnouncement({ id: this.data.announcement?.id, title: title.value, message: message.value } as Announcement);
  }

  saveAnnouncement(announcement: Announcement) {
    const isNew = !announcement.id;

    this.announcementService.upsertAnnouncement(announcement).subscribe({
      next: () => {
        this.snackService.showMessage(isNew ? 'announcement.created' : 'announcement.updated');
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.snackService.showGeneralError();
        console.error(error);
      },
    });
  }
}
