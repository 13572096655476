import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CondominiumService } from '../../services/condominium.service';
import { Condominium } from '../../../../models/condominium';
import { UserService } from '../../../user/services/user.service';
import { User } from '../../../../models/user';
import { KeyType } from '../../../../models/keyType';
import { ActivatedRoute, Router } from '@angular/router';
import { NominatimService } from '../../../ui/services/nominatim.service';
import { LoginService } from '../../../auth/services/login.service';
import { LockTypeService } from '../../../control-panel/services/lock-type.service';
import { LockType } from '../../../../models/lockType';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { lastValueFrom } from 'rxjs';
import { OpenersService } from '../../../ui/services/openers.service';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { DefaultSortingDataAccessor } from '../../../../helpers/DefaultSortingDataAccessor';
import { DefaultFilterPredicate } from '../../../../helpers/DefaultFilterPredicate';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';
import { KeyTypeService } from '../../../control-panel/services/key-type.service';
import { CondominiumModalComponent } from '../condominium-modal/condominium-modal.component';
import { DialogService } from '../../../ui/services/dialog.service';
import { CompanyService } from '../../../company/services/company.service';
import { Company } from '../../../../models/company';

@Component({
    selector: 'xkey-condominium',
    templateUrl: './condominium.component.html',
    styleUrls: ['./condominium.component.scss'],
    animations: [AnimationTriggerMetadata()],
    standalone: false
})
export class CondominiumComponent implements OnInit {
  dataSource = new MatTableDataSource<Condominium>([]);
  displayedColumnsDesktop = ['name', 'number', 'fullAddress', 'superIntendent', 'open'];
  displayedColumnsMobile = ['arrow', 'name', 'open'];
  expandedElement: Condominium | null;
  fromDashboard = false;
  filter: string;
  pageSize = 50;

  @Input() user: User;
  @Input() fromSearch = false;
  @Output() elementExpansionChanged = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private condominiumService: CondominiumService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private nominatimService: NominatimService,
    public handsetObserverService: HandsetObserverService,
    private lockTypeService: LockTypeService,
    private keyTypeService: KeyTypeService,
    private companyService: CompanyService,
    public loginService: LoginService,
    public openers: OpenersService,
    private dialogService: DialogService
  ) {}

  private _condominia: Condominium[] = [];

  get condominia(): Condominium[] {
    return this._condominia;
  }

  @Input()
  set condominia(val: Condominium[]) {
    this._condominia = val;

    if (this.fromSearch) {
      this.setupComponent().then();
    }
  }

  async ngOnInit() {
    await this.setupComponent();
  }

  async setupComponent() {
    if (this.router.url.indexOf('/etusivu') === 0) {
      this.fromDashboard = true;
      this.pageSize = 3;
    }

    if (this.fromSearch) {
      this.pageSize = 5;
    }

    await this.getCondominia();
  }

  async openCreateModal() {
    const users: User[] = await lastValueFrom(this.userService.getUsers());
    const lockTypes: LockType[] = await lastValueFrom(this.lockTypeService.getLockTypes());
    const keyTypes: KeyType[] = await lastValueFrom(this.keyTypeService.getKeyTypes());
    const owners: Company[] = await lastValueFrom(this.companyService.getCompanies());

    this.dialogService.showDialog(
      CondominiumModalComponent,
      {
        superIntendents: users,
        lockTypes,
        keyTypes: keyTypes.filter((k) => k.generalKey),
        apartmentKeyTypes: keyTypes.filter((k) => !k.generalKey),
        presetSuperIntendent: this.user ?? undefined,
        owners,
      },
      async () => {
        await this.setupComponent();
      }
    );
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  toggleExpansion(element) {
    this.expandedElement = this.expandedElement === element ? null : element;
    this.elementExpansionChanged.emit();
  }

  private async getCondominia() {
    if (!this.fromSearch) {
      if (this.user) {
        this.condominia = await lastValueFrom(this.userService.getUserCondominia(this.user.id));
      } else if (this.fromDashboard) {
        this.condominia = await lastValueFrom(this.userService.getUserCondominia(this.loginService.getCurrentUserId()));
      } else {
        this.condominia = await lastValueFrom(this.condominiumService.getCondominia());
      }
    }

    this.dataSource = new MatTableDataSource(this.condominia);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = DefaultFilterPredicate();
    this.dataSource.sortingDataAccessor = DefaultSortingDataAccessor();
    this.dataSource.sort = this.sort;
  }
}
