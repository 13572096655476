import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Permission } from '../../../../models/permission';
import { Role } from '../../../../models/role';
import { RoleService } from '../../services/role.service';
import { ReplaySubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';

@Component({
    selector: 'xkey-role-modal',
    templateUrl: './role-modal.component.html',
    styleUrls: ['./role-modal.component.scss'],
    standalone: false
})
export class RoleModalComponent implements OnInit {
  @ViewChild('multiSelect') multiSelect: MatSelect;

  public permissionCtrl: FormControl = new FormControl();
  public permissionFilterCtrl: FormControl = new FormControl();
  public filteredPermissions: ReplaySubject<Permission[]> = new ReplaySubject<Permission[]>(1);

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private snackService: SnackService,
    public dialogRef: MatDialogRef<RoleModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      role?: Role;
      permissions?: Permission[];
    }
  ) {
    data.role = data.role ?? new Role();
    data.permissions = data.permissions ?? [];
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.permissionCtrl.setValue(this.data.role.permissions ? this.data.role.permissions.map((p) => p.id) : []);

    this.form = this.formBuilder.group({
      name: [this.data.role.name, Validators.required],
      canBeInvited: [this.data.role.canBeInvited],
      permissions: this.permissionCtrl,
    });

    this.filteredPermissions.next(this.data.permissions.slice());

    this.permissionFilterCtrl.valueChanges.subscribe(() => {
      this.filterPermissions();
    });
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.data.role.name = this.f.name.value;
    this.data.role.canBeInvited = this.f.canBeInvited.value;
    this.data.role.permissions = this.f.permissions.value;
    this.saveRole(this.data.role);
  }

  saveRole(role: Role) {
    const isNew = !role.id;

    this.roleService.upsertRole(role).subscribe({
      next: (result) => {
        this.snackService.showMessage(isNew ? 'role.created' : 'role.updated');
        this.dialogRef.close(result);
      },
      error: (error) => {
        console.error(error);
        this.snackService.showGeneralError();
      },
    });
  }

  protected filterPermissions() {
    if (!this.data.permissions) {
      return;
    }

    let search = this.permissionFilterCtrl.value;
    if (!search) {
      this.filteredPermissions.next(this.data.permissions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPermissions.next(this.data.permissions.filter((bank) => bank.name.toLowerCase().indexOf(search) > -1));
  }
}
