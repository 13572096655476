import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Invitation } from '../../../../models/invitation';
import { Role } from '../../../../models/role';
import { InvitationService } from '../../services/invitation.service';
import { Company } from '../../../../models/company';
import { ReplaySubject } from 'rxjs';
import { FilterByField } from '../../../../helpers/DropdownFilters';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';

@Component({
    selector: 'xkey-invitation-modal',
    templateUrl: './invitation-modal.component.html',
    styleUrls: ['./invitation-modal.component.scss'],
    standalone: false
})
export class InvitationModalComponent implements OnInit {
  public roleCtrl: FormControl = new FormControl();
  public roleFilterCtrl: FormControl = new FormControl();
  public filteredRoles: ReplaySubject<Role[]> = new ReplaySubject<Role[]>(1);

  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private invitationService: InvitationService,
    private snackService: SnackService,
    public dialogRef: MatDialogRef<InvitationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      invitation?: Invitation;
      roles?: Role[];
      presetRole?: Role;
      companies?: Company[];
      presetCompany?: Company;
      email?: string;
    }
  ) {
    data.invitation = data.invitation ?? new Invitation();
    data.roles = data.roles ?? [];
    data.companies = data.companies ?? [];
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.roleCtrl.setValidators(Validators.required);
    this.roleCtrl.setValue(this.data.presetRole?.id ?? (this.data.invitation.role as Role)?.id ?? undefined);

    this.companyCtrl.setValidators(Validators.required);
    this.companyCtrl.setValue(this.data.presetCompany?.id ?? (this.data.invitation.company as Company)?.id ?? undefined);

    this.form = this.formBuilder.group({
      role: this.roleCtrl,
      company: this.companyCtrl,
      email: [this.data.email ?? '', [Validators.required, Validators.email]],
    });

    this.filteredRoles.next(this.data.roles.slice());
    this.filteredCompanies.next(this.data.companies.slice());

    this.roleFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredRoles, this.data.roles, 'name', this.roleFilterCtrl.value?.toLowerCase());
    });

    this.companyFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredCompanies, this.data.companies, 'name', this.companyFilterCtrl.value?.toLowerCase());
    });
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.data.invitation.roleId = this.f.role.value;
    this.data.invitation.companyId = this.f.company.value;
    this.data.invitation.email = this.f.email.value;

    this.saveInvitation(this.data.invitation);
  }

  saveInvitation(invitation: Invitation) {
    const isNew = !invitation.id;

    this.invitationService.upsertInvitation(invitation).subscribe({
      next: (result) => {
        this.snackService.showMessage(isNew ? 'invitation.created' : 'invitation.updated');
        this.dialogRef.close(result);
      },
      error: (error) => {
        console.error(error);
        this.snackService.showGeneralError();
      },
    });
  }
}
