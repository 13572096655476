import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { KeyService } from '../../../key/services/key.service';
import { Key } from '../../../../models/key';
import { Apartment } from '../../../../models/apartment';
import { ApartmentService } from '../../../apartment/services/apartment.service';
import { ResidentService } from '../../../resident/services/resident.service';
import { Condominium } from '../../../../models/condominium';
import { CondominiumService } from '../../services/condominium.service';
import { LoginService } from '../../../auth/services/login.service';
import { KeyHistory } from '../../../../models/keyhistory';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { lastValueFrom } from 'rxjs';
import { CondominiumKey } from '../../../../models/condominiumKey';
import { OpenersService } from '../../../ui/services/openers.service';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { DefaultSortingDataAccessor } from '../../../../helpers/DefaultSortingDataAccessor';
import { DefaultFilterPredicate } from '../../../../helpers/DefaultFilterPredicate';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';

@Component({
    selector: 'xkey-condominium-keys',
    templateUrl: './condominiumKeys.component.html',
    styleUrls: ['./condominiumKeys.component.scss'],
    animations: [AnimationTriggerMetadata()],
    standalone: false
})
export class CondominiumKeysComponent implements OnInit {
  data: CondominiumKey[] = [];
  keys: Key[] = [];
  keyHistories: KeyHistory[] = [];
  dataSource = new MatTableDataSource<CondominiumKey>([]);
  displayedColumnsDesktop = ['apartmentOrCondominium', 'type', 'keyCount'];
  displayedColumnsMobile = ['arrow', 'apartmentOrCondominium'];
  expandedElement: CondominiumKey | null;

  @Input() condominium: Condominium;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private keyService: KeyService,
    private apartmentService: ApartmentService,
    private residentService: ResidentService,
    private condominiumService: CondominiumService,
    public handsetObserverService: HandsetObserverService,
    public openers: OpenersService,
    public loginService: LoginService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setupComponent();
  }

  async setupComponent() {
    await this.getKeys();
  }

  async getKeys() {
    this.keys = await lastValueFrom(this.condominiumService.getCondominiumKeys(this.condominium.id, true));

    this.keys.forEach((key: Key) => {
      if (key.apartment && !this.data.some((d) => d.apartment && d.apartment.id === (key.apartment as Apartment).id)) {
        const withSameApartment = this.keys.filter((k) => k.apartment && (key.apartment as Apartment).id === (k.apartment as Apartment).id);

        const condominiumKey = new CondominiumKey();
        condominiumKey.apartment = key.apartment as Apartment;
        condominiumKey.type = key.type;
        condominiumKey.keyCount = withSameApartment.length;
        condominiumKey.id = this.data.length + 1;

        this.data.push(condominiumKey);
      } else if (key.condominium && !this.data.some((d) => d.condominium?.id === (key.condominium as Condominium).id && d.type === key.type)) {
        const withSameCondominiumAndType = this.keys.filter((k) => (key.condominium as Condominium)?.id === (k.condominium as Condominium)?.id && key.type === k.type);

        const condominiumKey = new CondominiumKey();
        condominiumKey.condominium = key.condominium as Condominium;
        condominiumKey.type = key.type;
        condominiumKey.keyCount = withSameCondominiumAndType.length;
        condominiumKey.id = this.data.length + 1;

        this.data.push(condominiumKey);
      }
    });

    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.filter = '';
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = DefaultFilterPredicate();
    this.dataSource.sortingDataAccessor = DefaultSortingDataAccessor();
    this.dataSource.sort = this.sort;
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
