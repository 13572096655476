import { NgModule, inject, provideAppInitializer } from '@angular/core';
import { CommonModule } from '@angular/common';
import { initRouterService, RouterService } from './services/router.service';
import { RouterModule, Routes } from '@angular/router';
import { AnnouncementComponent } from '../announcement/components/announcement/announcement.component';
import { AuthGuard } from '../auth/guards/auth-guard.service';
import { ApartmentSingleComponent } from '../apartment/components/apartment-single/apartment-single.component';
import { AuditComponent } from '../audit/components/audit/audit.component';
import { CompanyComponent } from '../company/components/company/company.component';
import { CompanySingleComponent } from '../company/components/company-single/company-single.component';
import { CondominiumComponent } from '../condominium/components/condominium/condominium.component';
import { CondominiumSingleComponent } from '../condominium/components/condominium-single/condominium-single.component';
import { ControlPanelComponent } from '../control-panel/components/control-panel/control-panel.component';
import { DashboardComponent } from '../dashboard/components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from '../auth/components/forgot-password/forgot-password.component';
import { KeySingleComponent } from '../key/components/key-single/key-single.component';
import { KeyHistorySingleComponent } from '../key/components/key-history-single/key-history-single.component';
import { LoginComponent } from '../auth/components/login/login.component';
import { MyPageComponent } from '../user/components/my-page/my-page.component';
import { RegisterComponent } from '../auth/components/register/register.component';
import { ResidentSingleComponent } from '../resident/components/resident-single/resident-single.component';
import { UserSingleComponent } from '../user/components/user-single/user-single.component';
import { PrivacyPolicyComponent } from '../../components/privacy-policy/privacy-policy.component';
import { ResidentialPropertyComponent } from '../condominium/components/residential-property/residential-property.component';

const routes: Routes = [
  { path: 'ilmoitukset', component: AnnouncementComponent, canActivate: [AuthGuard] },
  { path: 'asunnot/:id', component: ApartmentSingleComponent, canActivate: [AuthGuard] },
  { path: 'asunnot/:id/:tab', component: ApartmentSingleComponent, canActivate: [AuthGuard] },
  { path: 'seuranta', component: AuditComponent, canActivate: [AuthGuard] },
  { path: 'yritykset', component: CompanyComponent, canActivate: [AuthGuard] },
  { path: 'yritykset/:id', component: CompanySingleComponent, canActivate: [AuthGuard] },
  { path: 'yritykset/:id/:tab', component: CompanySingleComponent, canActivate: [AuthGuard] },
  { path: 'kiinteistot', component: CondominiumComponent, canActivate: [AuthGuard] },
  { path: 'kohteet', component: ResidentialPropertyComponent, canActivate: [AuthGuard] },
  { path: 'kiinteistot/:id', component: CondominiumSingleComponent, canActivate: [AuthGuard] },
  { path: 'kiinteistot/:id/:tab', component: CondominiumSingleComponent, canActivate: [AuthGuard] },
  { path: 'hallinta', component: ControlPanelComponent, canActivate: [AuthGuard] },
  { path: 'hallinta/:tab', component: ControlPanelComponent, canActivate: [AuthGuard] },
  { path: 'etusivu', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'unohdin-salasanani', component: ForgotPasswordComponent },
  { path: 'unohdin-salasanani/:code', component: ForgotPasswordComponent },
  { path: 'avaimet/:id', component: KeySingleComponent, canActivate: [AuthGuard] },
  { path: 'avaimet/:id/:tab', component: KeySingleComponent, canActivate: [AuthGuard] },
  { path: 'avain-historiat/:id', component: KeyHistorySingleComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'oma', component: MyPageComponent, canActivate: [AuthGuard] },
  { path: 'oma/:tab', component: MyPageComponent, canActivate: [AuthGuard] },
  { path: 'register/:code', component: RegisterComponent },
  { path: 'asukkaat/:id', component: ResidentSingleComponent, canActivate: [AuthGuard] },
  { path: 'kayttajat/:id', component: UserSingleComponent, canActivate: [AuthGuard] },
  { path: 'kayttajat/:id/:tab', component: UserSingleComponent, canActivate: [AuthGuard] },
  { path: 'tietosuojaseloste', component: PrivacyPolicyComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: LoginComponent },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: [
    provideAppInitializer(() => {
        const initializerFn = (initRouterService)(inject(RouterService));
        return initializerFn();
      }),
    RouterService,
  ],
})
export class RoutingModule {}
