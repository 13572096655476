import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { KeyHistoryService } from '../../services/keyhistory.service';
import { EventType, KeyHistory } from '../../../../models/keyhistory';
import { ResidentService } from '../../../resident/services/resident.service';
import { UserService } from '../../../user/services/user.service';
import { KeyService } from '../../services/key.service';
import { Key } from '../../../../models/key';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { lastValueFrom } from 'rxjs';
import { OpenersService } from '../../../ui/services/openers.service';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { DefaultFilterPredicate } from '../../../../helpers/DefaultFilterPredicate';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';
import { DefaultSortingDataAccessor } from '../../../../helpers/DefaultSortingDataAccessor';

@Component({
    selector: 'xkey-key-history',
    templateUrl: './keyhistory.component.html',
    styleUrls: ['./keyhistory.component.scss'],
    animations: [AnimationTriggerMetadata()],
    standalone: false
})
export class KeyHistoryComponent implements OnInit {
  keyHistories: KeyHistory[] = [];
  dataSource = new MatTableDataSource<KeyHistory>([]);
  displayedColumnsDesktop = ['date', 'event', 'holder', 'stamp', 'open'];
  displayedColumnsMobile = ['arrow', 'date', 'open'];
  expandedElement: KeyHistory | null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private keyHistoryService: KeyHistoryService,
    private residentService: ResidentService,
    private userService: UserService,
    private keyService: KeyService,
    public handsetObserverService: HandsetObserverService,
    public openers: OpenersService
  ) {}

  private _key: Key;

  get key(): Key {
    return this._key;
  }

  @Input()
  set key(val: Key) {
    this._key = val;
    this.setupComponent().then();
  }

  async ngOnInit() {
    await this.setupComponent();
  }

  async setupComponent() {
    await this.getKeyHistories();
  }

  async getKeyHistories() {
    if (this._key) {
      this.keyHistories = await lastValueFrom(this.keyService.getKeyKeyHistories(this._key.id));
    } else {
      this.keyHistories = await lastValueFrom(this.keyHistoryService.getKeyHistories());
    }

    this.dataSource = new MatTableDataSource(this.keyHistories);
    this.dataSource.filter = '';
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = DefaultFilterPredicate();
    this.dataSource.sortingDataAccessor = DefaultSortingDataAccessor();
    this.dataSource.sort = this.sort;
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getEventType(event: number) {
    return EventType[event];
  }
}
