import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';
import { User } from '../../../../models/user';
import { Company } from '../../../../models/company';
import { CompanyService } from '../../../company/services/company.service';
import { LoginService } from '../../../auth/services/login.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { lastValueFrom } from 'rxjs';
import { OpenersService } from '../../../ui/services/openers.service';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { DefaultFilterPredicate } from '../../../../helpers/DefaultFilterPredicate';
import { DefaultSortingDataAccessor } from '../../../../helpers/DefaultSortingDataAccessor';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';

@Component({
    selector: 'xkey-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    animations: [AnimationTriggerMetadata()],
    standalone: false
})
export class UserComponent implements OnInit {
  dataSource = new MatTableDataSource<User>([]);
  displayedColumnsDesktop = ['fullName', 'phone', 'email', 'company', 'active', 'open'];
  displayedColumnsMobile = ['arrow', 'fullName', 'open'];
  expandedElement: User | null;
  pageSize = 50;

  @Input() company: Company;
  @Input() fromSearch = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    public handsetObserverService: HandsetObserverService,
    public loginService: LoginService,
    public openers: OpenersService
  ) {}

  private _users: User[] = [];

  get users(): User[] {
    return this._users;
  }

  @Input()
  set users(val: User[]) {
    this._users = val;

    if (this.fromSearch) {
      this.setupComponent().then();
    }
  }

  async ngOnInit() {
    await this.setupComponent();
  }

  async setupComponent() {
    await this.getUsers();
  }

  async getUsers() {
    if (!this.fromSearch) {
      if (this.company) {
        this.users = await lastValueFrom(this.companyService.getCompanyUsers(this.company.id));
      } else {
        this.users = await lastValueFrom(this.userService.getUsers());
      }
    }

    this.dataSource = new MatTableDataSource(this.users);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = DefaultFilterPredicate();
    this.dataSource.sortingDataAccessor = DefaultSortingDataAccessor();
    this.dataSource.sort = this.sort;
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
