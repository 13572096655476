import { Component, OnInit } from '@angular/core';
import { KeyService } from '../../services/key.service';
import { ApartmentService } from '../../../apartment/services/apartment.service';
import { ResidentService } from '../../../resident/services/resident.service';
import { CondominiumService } from '../../../condominium/services/condominium.service';
import { UserService } from '../../../user/services/user.service';
import { LockTypeService } from '../../../control-panel/services/lock-type.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { LoginService } from '../../../auth/services/login.service';
import { OpenersService } from '../../../ui/services/openers.service';
import { TranslateService } from '@ngx-translate/core';
import { Key } from '../../../../models/key';
import { KeyType } from '../../../../models/keyType';
import { ConfirmModalComponent } from '../../../ui/components/confirm-modal/confirm-modal.component';
import { LockType } from '../../../../models/lockType';
import { Apartment } from '../../../../models/apartment';
import { Condominium } from '../../../../models/condominium';
import { KeyModalComponent } from '../key-modal/key-modal.component';
import { lastValueFrom } from 'rxjs';
import { ChangeDueDateModalComponent } from '../change-due-date-modal/change-due-date-modal.component';
import { KeyTypeService } from '../../../control-panel/services/key-type.service';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { SnackService } from '../../../ui/services/snack.service';
import { DialogService } from '../../../ui/services/dialog.service';
import { CompanyService } from '../../../company/services/company.service';
import { Company } from '../../../../models/company';

@Component({
    selector: 'xkey-key-single',
    templateUrl: './key-single.component.html',
    styleUrls: ['./key-single.component.scss'],
    standalone: false
})
export class KeySingleComponent implements OnInit {
  selectedKey: Key;
  tabIndex = 0;
  userHasRightCompany = false;

  constructor(
    private keyService: KeyService,
    private apartmentService: ApartmentService,
    private residentService: ResidentService,
    private condominiumService: CondominiumService,
    private userService: UserService,
    private lockTypeService: LockTypeService,
    private keyTypeService: KeyTypeService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public handsetObserverService: HandsetObserverService,
    public loginService: LoginService,
    public openers: OpenersService,
    private snackService: SnackService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.setupComponent(params['id']);
    });

    this.tabIndex = parseInt(this.route.snapshot.paramMap.get('tab'), 10);
  }

  setupComponent(id) {
    this.keyService.getKey(id).subscribe((key: Key) => {
      this.selectedKey = key;
      this.userHasRightCompany = this.loginService.userHasRightCompany(key.owner.id);
    });
  }

  openModifyModal = async (key: Key) => {
    const lockTypes: LockType[] = await lastValueFrom(this.lockTypeService.getLockTypes());
    const keyTypes: KeyType[] = await lastValueFrom(this.keyTypeService.getKeyTypes());
    const apartments: Apartment[] = await lastValueFrom(this.apartmentService.getApartments());
    const condominia: Condominium[] = await lastValueFrom(this.condominiumService.getCondominia());
    const owners: Company[] = await lastValueFrom(this.companyService.getCompanies());

    this.dialogService.showDialog(
      KeyModalComponent,
      {
        key,
        lockTypes,
        keyTypes,
        apartments,
        condominia,
        owners,
      },
      () => {
        this.keyService.getKey(this.selectedKey.id).subscribe((updatedKey: Key) => {
          this.selectedKey = updatedKey;
        });
      }
    );
  };

  openDeleteModal(keyId: number) {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-key', title: this.translate.instant('key.deleteKey') }, () => {
      this.keyService.deleteKey(keyId).subscribe(() => {
        this.location.back();
      });
    });
  }

  openMarkLostModal(key: Key) {
    this.dialogService.showDialog(
      ConfirmModalComponent,
      {
        modalClass: 'modal-key',
        title: key.lost ? this.translate.instant('key.foundTitle') : this.translate.instant('key.lostTitle'),
        message: key.lost ? this.translate.instant('key.foundMessage') : this.translate.instant('key.lostMessage'),
      },
      () => {
        if (key.lost) {
          this.keyService.markFound(key).subscribe((updatedKey: Key) => {
            this.selectedKey = updatedKey;
            this.snackService.showMessage('key.markedFound');
          });
        } else {
          this.keyService.markLost(key).subscribe((updatedKey: Key) => {
            this.selectedKey = updatedKey;
            this.snackService.showMessage('key.markedLost');
          });
        }
      }
    );
  }

  openSendReminderModal(key: Key) {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-key', title: this.translate.instant('key.sendReminderTitle'), message: this.translate.instant('key.sendReminderMessage') }, () => {
      this.keyService.sendReminder(key).subscribe(() => {
        this.snackService.showMessage('key.reminderSent');
      });
    });
  }

  tabChanged(event) {
    this.tabIndex = event.index;
    this.location.go('/avaimet/' + this.selectedKey.id + '/' + this.tabIndex);
  }

  getCurrentHolderValue(selectedKey: Key) {
    return selectedKey.getHolderToString();
  }

  async openCurrentHolder(selectedKey: Key) {
    if (selectedKey.currentHolder) {
      await this.openers.openResident(selectedKey.currentHolder);
    } else if (selectedKey.currentHolderInternal) {
      await this.openers.openUser(selectedKey.currentHolderInternal);
    }
  }

  openChangeDueDateModal(selectedKey: Key) {
    this.dialogService.showDialog(ChangeDueDateModalComponent, { key: { ...selectedKey } }, () => {
      this.keyService.getKey(this.selectedKey.id).subscribe((updatedKey: Key) => {
        this.selectedKey = updatedKey;
      });
    });
  }
}
