import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../../auth/services/login.service';
import { VersionService } from '../../services/version.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { HandsetObserverService } from '../../services/handset-observer.service';

const { version: appVersion } = require('../../../../../../package.json');

@Component({
    selector: 'xkey-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    standalone: false
})
export class NavbarComponent implements OnInit, AfterViewInit {
  backendVersion: string;
  appVersion: string;
  showArrowLeft = false;
  showArrowRight = false;
  protected readonly environment = environment;
  @ViewChild('navcontainer') private navContainer: ElementRef<HTMLElement>;

  constructor(
    public loginService: LoginService,
    private versionService: VersionService,
    private router: Router,
    public handsetObserverService: HandsetObserverService
  ) {}

  ngOnInit(): void {
    this.appVersion = appVersion;

    this.versionService.get().subscribe((response: any) => {
      this.backendVersion = response.version;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showArrowRight = this.navContainer.nativeElement.scrollWidth - window.innerWidth > 30;

      const selectedLink = document.getElementsByClassName('active-link')[0] || undefined;
      if (selectedLink) {
        selectedLink.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
        });
      }
    }, 0);

    this.navContainer.nativeElement.addEventListener('scroll', () => {
      this.showArrowLeft = this.navContainer.nativeElement.scrollLeft > 30;
      this.showArrowRight = this.navContainer.nativeElement.scrollWidth > this.navContainer.nativeElement.scrollLeft + window.innerWidth + 30;
    });
  }

  async logout() {
    this.loginService.logout();
    await this.router.navigate(['/']);
  }

  scrollLeft() {
    this.navContainer.nativeElement.scrollBy({
      left: -100,
      behavior: 'smooth',
    });
  }

  scrollRight() {
    this.navContainer.nativeElement.scrollBy({
      left: 100,
      behavior: 'smooth',
    });
  }
}
