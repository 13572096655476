import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { KeyService } from '../../services/key.service';
import { Key } from '../../../../models/key';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';

@Component({
    selector: 'xkey-delete-key-modal',
    templateUrl: './change-due-date-modal.component.html',
    styleUrls: ['./change-due-date-modal.component.scss'],
    standalone: false
})
export class ChangeDueDateModalComponent implements OnInit {
  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private keyService: KeyService,
    private snackService: SnackService,
    public dialogRef: MatDialogRef<ChangeDueDateModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      key: Key;
    }
  ) {}

  get f() {
    return this.formGroup.controls;
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      dueDate: [],
    });
  }

  changeDueDate() {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid) {
      return;
    }

    const keyToUpdate = {
      id: this.data.key.id,
      dueDate: this.f.dueDate.value,
    };

    this.keyService.changeDueDate(keyToUpdate).subscribe({
      next: (result) => {
        this.snackService.showMessage('key.updated');
        this.dialogRef.close(result);
      },
      error: (error) => {
        console.error(error);
        this.snackService.showGeneralError();
      },
    });
  }
}
