import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../auth/services/login.service';

@Component({
    selector: 'xkey-control-panel',
    templateUrl: './control-panel.component.html',
    styleUrls: ['./control-panel.component.scss'],
    standalone: false
})
export class ControlPanelComponent implements OnInit {
  tabIndex = 0;

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    public loginService: LoginService
  ) {}

  ngOnInit() {
    this.tabIndex = parseInt(this.activatedRoute.snapshot.paramMap.get('tab'), 10);
  }

  tabChanged(event) {
    this.tabIndex = event.index;
    this.location.go('/hallinta/' + this.tabIndex);
  }
}
