import { Component, OnInit } from '@angular/core';
import { CondominiumService } from '../../services/condominium.service';
import { UserService } from '../../../user/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { LockTypeService } from '../../../control-panel/services/lock-type.service';
import { LoginService } from '../../../auth/services/login.service';
import { OpenersService } from '../../../ui/services/openers.service';
import { Condominium } from '../../../../models/condominium';
import { User } from '../../../../models/user';
import { LockType } from '../../../../models/lockType';
import { KeyType } from '../../../../models/keyType';
import { CondominiumModalComponent } from '../condominium-modal/condominium-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { KeyTypeService } from '../../../control-panel/services/key-type.service';
import { DialogService } from '../../../ui/services/dialog.service';
import { ConfirmModalComponent } from '../../../ui/components/confirm-modal/confirm-modal.component';
import { Company } from '../../../../models/company';
import { CompanyService } from '../../../company/services/company.service';
import { CondominiumTransferModalComponent } from '../condominium-transfer-modal/condominium-transfer-modal.component';

@Component({
    selector: 'xkey-condominium-single',
    templateUrl: './condominium-single.component.html',
    styleUrls: ['./condominium-single.component.scss'],
    standalone: false
})
export class CondominiumSingleComponent implements OnInit {
  selectedCondominium: Condominium;
  tabIndex = 1;

  constructor(
    private condominiumService: CondominiumService,
    private userService: UserService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    public handsetObserverService: HandsetObserverService,
    private lockTypeService: LockTypeService,
    private keyTypeService: KeyTypeService,
    private companyService: CompanyService,
    private translate: TranslateService,
    public loginService: LoginService,
    public openers: OpenersService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {
      await this.setupComponent(params['id']);
    });

    const tab = this.route.snapshot.paramMap.get('tab');
    if (tab) {
      this.tabIndex = parseInt(tab, 10);
    }
  }

  async setupComponent(id: string) {
    this.selectedCondominium = await lastValueFrom(this.condominiumService.getCondominium(id));
  }

  async openModifyModal(condominium: Condominium) {
    const users: User[] = await lastValueFrom(this.userService.getUsers());
    const lockTypes: LockType[] = await lastValueFrom(this.lockTypeService.getLockTypes());
    const keyTypes: KeyType[] = await lastValueFrom(this.keyTypeService.getKeyTypes());
    const owners: Company[] = await lastValueFrom(this.companyService.getCompanies());

    this.dialogService.showDialog(
      CondominiumModalComponent,
      {
        condominium: { ...condominium },
        superIntendents: users,
        lockTypes,
        keyTypes: keyTypes.filter((k) => k.generalKey),
        apartmentKeyTypes: keyTypes.filter((k) => !k.generalKey),
        owners,
      },
      () => {
        this.condominiumService.getCondominium(this.selectedCondominium.id).subscribe((updatedCondominium: Condominium) => {
          this.selectedCondominium = updatedCondominium;
        });
      }
    );
  }

  async openTransferModal(condominium: Condominium) {
    const owners: Company[] = await lastValueFrom(this.companyService.getCompanies());

    this.dialogService.showDialog(
      CondominiumTransferModalComponent,
      {
        condominium: { ...condominium },
        owners,
      },
      () => {
        this.condominiumService.getCondominium(this.selectedCondominium.id).subscribe((updatedCondominium: Condominium) => {
          this.selectedCondominium = updatedCondominium;
        });
      }
    );
  }

  openDeleteModal(condominiumId: number) {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-condominium', title: this.translate.instant('condominium.deleteCondominium') }, () => {
      this.condominiumService.deleteCondominium(condominiumId).subscribe(async () => {
        await this.router.navigate(['/kiinteistot']);
      });
    });
  }

  tabChanged(event) {
    this.tabIndex = event.index;
    this.location.go('/kiinteistot/' + this.selectedCondominium.id + '/' + this.tabIndex);
  }
}
