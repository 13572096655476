import { Component, Inject, OnInit } from '@angular/core';
import { Apartment } from '../../../../models/apartment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Condominium } from '../../../../models/condominium';
import { ApartmentService } from '../../services/apartment.service';
import { ReplaySubject } from 'rxjs';
import { FieldGreaterThanValidator } from '../../../../helpers/FieldGreaterThanValidator';
import { FieldLessThanValidator } from '../../../../helpers/FieldLessThanValidator';
import { FilterByField } from '../../../../helpers/DropdownFilters';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';
import { Company } from '../../../../models/company';
import { LoginService } from '../../../auth/services/login.service';

@Component({
    selector: 'xkey-apartment-modal',
    templateUrl: './apartment-modal.component.html',
    styleUrls: ['./apartment-modal.component.scss'],
    standalone: false
})
export class ApartmentModalComponent implements OnInit {
  public condominiumCtrl: FormControl = new FormControl();
  public condominiumFilterCtrl: FormControl = new FormControl();
  public filteredCondominia: ReplaySubject<Condominium[]> = new ReplaySubject<Condominium[]>(1);

  public ownerCtrl: FormControl = new FormControl();
  public ownerFilterCtrl: FormControl = new FormControl();
  public filteredOwners: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private apartmentService: ApartmentService,
    private loginService: LoginService,
    private snackService: SnackService,
    public dialogRef: MatDialogRef<ApartmentModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { apartment?: Apartment; condominia?: Condominium[]; presetCondominium?: Condominium; multiple?: boolean; owners: Company[] }
  ) {
    if (!data.apartment) {
      data.apartment = new Apartment();
    }
  }

  get f() {
    return this.form.controls;
  }

  private static stripLastNumber(text: string = '') {
    const words = text.split(' ');

    if (words.length === 0) {
      return text;
    }

    const lastWord = words[words.length - 1];
    if (!isNaN(+lastWord)) {
      return words.slice(0, -1).join(' ');
    }

    return text;
  }

  ngOnInit() {
    this.condominiumCtrl.setValidators(Validators.required);
    this.condominiumCtrl.setValue(this.data.presetCondominium?.id ?? (this.data.apartment.condominium as Condominium)?.id ?? undefined);
    this.ownerCtrl.setValue((this.data.presetCondominium?.owner as Company)?.id ?? this.data.apartment.owner?.id ?? this.loginService.getCompany());

    this.form = this.formBuilder.group({
      addressStreet: [
        this.data.apartment.addressStreet ? ApartmentModalComponent.stripLastNumber(this.data.apartment.addressStreet) : ApartmentModalComponent.stripLastNumber(this.data.presetCondominium?.addressStreet),
        Validators.required,
      ],
      addressPostal: [this.data.apartment.addressPostalCode ?? this.data.presetCondominium?.addressPostalCode, [Validators.required, Validators.pattern('[0-9]{5,}')]],
      addressCity: [this.data.apartment.addressCity ?? this.data.presetCondominium?.addressCity, Validators.required],
      condominium: this.condominiumCtrl,
      owner: this.ownerCtrl,
      ...this.getMultipleFields(),
    });

    if (this.data.multiple) {
      this.form.get('lastNumber').setValidators([FieldGreaterThanValidator('firstNumber'), Validators.required]);
      this.form.get('firstNumber').setValidators([FieldLessThanValidator('lastNumber'), Validators.required]);
    }

    this.filteredCondominia.next(this.data.condominia.slice());
    this.filteredOwners.next(this.data.owners.slice());

    this.condominiumFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredCondominia, this.data.condominia, 'name', this.condominiumFilterCtrl.value?.toLowerCase());
    });

    this.ownerFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredOwners, this.data.owners, 'name', this.ownerFilterCtrl.value?.toLowerCase());
    });
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.data.apartment.condominiumId = this.f.condominium.value;
    this.data.apartment.addressStreet = this.f.addressStreet.value;
    this.data.apartment.addressPostalCode = this.f.addressPostal.value;
    this.data.apartment.addressCity = this.f.addressCity.value;
    this.data.apartment.ownerId = this.f.owner.value;

    if (this.data.multiple) {
      this.data.apartment.firstNumber = this.f.firstNumber.value;
      this.data.apartment.lastNumber = this.f.lastNumber.value;
    }

    this.saveApartment(this.data.apartment);
  }

  close() {
    this.dialogRef.close(false);
  }

  saveApartment(apartment: Apartment) {
    const isNew = !apartment.id;

    this.apartmentService.upsertApartment(apartment).subscribe({
      next: (result) => {
        this.snackService.showMessage(isNew ? 'apartment.created' : 'apartment.updated');
        this.dialogRef.close(result);
      },
      error: (error) => {
        this.snackService.showGeneralError();
        console.error(error);
      },
    });
  }

  updateValidity() {
    this.f.firstNumber.updateValueAndValidity();
    this.f.lastNumber.updateValueAndValidity();
  }

  private getMultipleFields() {
    if (this.data.multiple) {
      return {
        firstNumber: [1, Validators.required],
        lastNumber: [1, Validators.required],
      };
    }

    return {};
  }
}
