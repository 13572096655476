import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../../models/user';
import { Resident } from '../../../../models/resident';
import { UserService } from '../../../user/services/user.service';
import { ResidentService } from '../../../resident/services/resident.service';
import { Apartment } from '../../../../models/apartment';
import { ApartmentService } from '../../../apartment/services/apartment.service';
import { LoginService } from '../../../auth/services/login.service';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { PhoneNumberValidator } from '../../../../helpers/PhoneNumberValidator';
import { KeyService } from '../../services/key.service';
import { CondominiumService } from '../../../condominium/services/condominium.service';
import { ReplaySubject } from 'rxjs';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from '../../../company/services/company.service';
import { KeyAgreementResponse } from '../../../../models/keyAgreementResponse';

@Component({
    selector: 'xkey-delete-key-modal',
    templateUrl: './give-key-modal.component.html',
    styleUrls: ['./give-key-modal.component.scss'],
    standalone: false
})
export class GiveKeyModalComponent implements OnInit {
  users: [User];
  residents: [Resident];
  signature: string;
  mandate: string | ArrayBuffer;
  mandateName: string;
  step1Submitted = false;
  firstFormGroup: FormGroup;
  keyAgreement: string;
  residentCtrl: FormControl = new FormControl();
  residentFilterCtrl: FormControl = new FormControl();
  filteredResidents: ReplaySubject<Resident[]> = new ReplaySubject<[]>(1);
  userCtrl: FormControl = new FormControl();
  userFilterCtrl: FormControl = new FormControl();
  filteredUsers: ReplaySubject<User[]> = new ReplaySubject<[]>(1);
  signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 1,
    maxWidth: 2,
    canvasHeight: 0,
    canvasWidth: 0,
    backgroundColor: 'white',
  };
  @ViewChild('signature') signaturePad: SignaturePadComponent;

  constructor(
    private userService: UserService,
    private residentService: ResidentService,
    private apartmentService: ApartmentService,
    private condominiumService: CondominiumService,
    private companyService: CompanyService,
    public loginService: LoginService,
    private formBuilder: FormBuilder,
    private keyService: KeyService,
    public dialogRef: MatDialogRef<GiveKeyModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      apartment?: Apartment;
      keys: string[];
    }
  ) {}

  get f() {
    return this.firstFormGroup.controls;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeCanvas();
  }

  ngOnInit() {
    this.companyService.getKeyAgreement(this.loginService.getCompany()).subscribe((keyAgreementResponse: KeyAgreementResponse) => {
      this.keyAgreement = keyAgreementResponse?.data
        .replace('[LENDER]', '')
        .replace('[DATE]', new Date().toISOString().slice(0, 10))
        .replace('[DUE_DATE]', '')
        .replace('[RESIDENTIAL_PROPERTY]', '')
        .replace('[KEY_ID]', '')
        .replace('[STAMP]', '');
    });

    if (this.data.apartment) {
      this.apartmentService.getApartmentResidents(this.data.apartment.id).subscribe((residents: [Resident]) => {
        this.residents = residents;
        this.filteredResidents.next(this.residents.slice());
      });
    } else {
      this.residentService.getResidents().subscribe((residents: [Resident]) => {
        this.residents = residents;
        this.filteredResidents.next(this.residents.slice());
      });
    }

    this.userService.getUsers().subscribe((users: [User]) => {
      this.users = users;
      this.filteredUsers.next(this.users.slice());

      this.residentCtrl.setValidators(Validators.required);

      this.firstFormGroup = this.formBuilder.group({
        selectedType: ['resident', Validators.required],
        resident: this.residentCtrl,
        user: this.userCtrl,
        firstName: [''],
        lastName: [''],
        email: [''],
        phone: [''],
        selectedPeriodType: ['openEnded'],
        dueDate: [],
        canBeReGiven: [false, Validators.required],
      });
    });

    this.residentFilterCtrl.valueChanges.subscribe(() => {
      this.filterResidents();
    });

    this.userFilterCtrl.valueChanges.subscribe(() => {
      this.filterUsers();
    });
  }

  resizeCanvas() {
    if (document.getElementsByTagName('canvas')) {
      const canvas = document.getElementsByTagName('canvas')[0];
      canvas.width = document.getElementById('signaturePad')?.clientWidth ?? 200;
      canvas.height = 200;
    }
  }

  drawComplete() {
    this.signature = this.signaturePad.toDataURL();
  }

  clear() {
    this.signaturePad.clear();
  }

  giveKeys() {
    this.keyService
      .giveKey({
        signature: this.signature,
        keys: this.data.keys,
        currentHolderOther:
          this.f.selectedType.value === 'other'
            ? {
                firstName: this.f.firstName.value,
                lastName: this.f.lastName.value,
                email: this.f.email.value,
                phone: this.f.phone.value,
              }
            : undefined,
        residentId: this.f.selectedType.value !== 'other' && this.f.resident.value ? this.f.resident.value : undefined,
        userId: this.f.selectedType.value !== 'other' && this.f.user.value ? this.f.user.value : undefined,
        dueDate: this.f.dueDate.value,
        canBeReGiven: this.f.canBeReGiven.value,
        mandate: this.mandate,
      })
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  public dropped(droppedFiles: NgxFileDropEntry[]) {
    for (const droppedFile of droppedFiles) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.mandateName = droppedFile.relativePath;

          const myReader: FileReader = new FileReader();
          myReader.onloadend = () => {
            this.mandate = myReader.result;
          };

          myReader.readAsDataURL(file);
        });
      }
    }
  }

  changeType(event) {
    this.firstFormGroup.clearValidators();

    if (event.value === 'resident') {
      this.firstFormGroup.controls.resident.setValidators(Validators.required);
      this.firstFormGroup.controls.user.setErrors(null);
      this.firstFormGroup.controls.firstName.setErrors(null);
      this.firstFormGroup.controls.lastName.setErrors(null);
      this.firstFormGroup.controls.email.setErrors(null);
      this.firstFormGroup.controls.phone.setErrors(null);
    } else if (event.value === 'user') {
      this.firstFormGroup.controls.user.setValidators(Validators.required);
      this.firstFormGroup.controls.resident.setErrors(null);
      this.firstFormGroup.controls.firstName.setErrors(null);
      this.firstFormGroup.controls.lastName.setErrors(null);
      this.firstFormGroup.controls.email.setErrors(null);
      this.firstFormGroup.controls.phone.setErrors(null);
    } else if (event.value === 'other') {
      this.firstFormGroup.controls.firstName.setValidators(Validators.required);
      this.firstFormGroup.controls.lastName.setValidators(Validators.required);
      // this.firstFormGroup.controls.email.setValidators([Validators.required, Validators.email]);
      this.firstFormGroup.controls.email.setValidators(Validators.email);
      this.firstFormGroup.controls.email.setErrors(null);
      this.firstFormGroup.controls.phone.setValidators(PhoneNumberValidator('FI'));
      this.firstFormGroup.controls.resident.setErrors(null);
      this.firstFormGroup.controls.user.setErrors(null);
    }
  }

  changePeriod(event) {
    if (event.value === 'fixed') {
      this.firstFormGroup.controls.dueDate.setValidators(Validators.required);
    } else if (event.value === 'openEnded') {
      this.firstFormGroup.controls.dueDate.setValue(null);
      this.firstFormGroup.controls.dueDate.setValidators(null);
      this.firstFormGroup.controls.dueDate.setErrors(null);
    }
  }

  submitStep1() {
    this.step1Submitted = true;
  }

  getHasSignature() {
    return !!this.signature;
  }

  protected filterUsers() {
    if (!this.users) {
      return;
    }

    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUsers.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredUsers.next(
      this.users.filter((user) => {
        return user.fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }

  protected filterResidents() {
    if (!this.residents) {
      return;
    }

    let search = this.residentFilterCtrl.value;
    if (!search) {
      this.filteredResidents.next(this.residents.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredResidents.next(
      this.residents.filter((resident) => {
        return resident.fullName.toLowerCase().indexOf(search) > -1;
      })
    );
  }
}
