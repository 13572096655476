import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';

@Component({
    selector: 'xkey-change-password-modal',
    templateUrl: './change-password-modal.component.html',
    styleUrls: ['./change-password-modal.component.scss'],
    standalone: false
})
export class ChangePasswordModalComponent implements OnInit {
  form: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private snackService: SnackService,
    public dialogRef: MatDialogRef<ChangePasswordModalComponent>
  ) {}

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*[\\W])(?=.*[0-9])(?=.*[a-z]).{8,128}$')]],
      passwordAgain: ['', Validators.required],
    });
  }

  save() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.f.password.value !== this.f.passwordAgain.value) {
      this.form.controls['passwordAgain'].setErrors({ noMatch: true });
      return;
    }

    this.saveUser(this.f.password.value);
  }

  saveUser(password: string) {
    if (password) {
      this.userService.changePassword({ password }).subscribe({
        next: (result) => {
          this.snackService.showMessage('user.passwordChanged');
          this.dialogRef.close(result);
        },
        error: (error) => {
          this.snackService.showGeneralError();
          console.error(error);
        },
      });
    }
  }
}
