import { Component, OnInit } from '@angular/core';
import { User } from '../../../../models/user';
import { LoginService } from '../../../auth/services/login.service';
import { Company } from '../../../../models/company';
import { Role } from '../../../../models/role';
import { RoleService } from '../../../control-panel/services/role.service';
import { CompanyService } from '../../../company/services/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModifyUserModalComponent } from '../modify-user-modal/modify-user-modal.component';
import { UserService } from '../../services/user.service';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';
import { OpenersService } from '../../../ui/services/openers.service';
import { lastValueFrom } from 'rxjs';
import { Location } from '@angular/common';
import { UserSettings } from '../../../../models/userSettings';
import { SnackService } from '../../../ui/services/snack.service';
import { DialogService } from '../../../ui/services/dialog.service';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';

@Component({
    selector: 'xkey-my-page',
    templateUrl: './my-page.component.html',
    styleUrls: ['./my-page.component.scss'],
    standalone: false
})
export class MyPageComponent implements OnInit {
  currentUser: User;
  company: Company;
  role: Role;
  tabIndex = 0;
  settings: UserSettings;

  constructor(
    public loginService: LoginService,
    private userService: UserService,
    private roleService: RoleService,
    private companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private snackService: SnackService,
    public openers: OpenersService,
    private dialogService: DialogService,
    public handsetObserverService: HandsetObserverService
  ) {}

  async ngOnInit() {
    await this.getData();
    this.tabIndex = parseInt(this.route.snapshot.paramMap.get('tab'), 10);

    this.userService.getCurrentUserSettings().subscribe({
      next: (settings: UserSettings) => {
        this.settings = settings;
      },
      error: (error) => {
        console.error(error);
        this.snackService.showGeneralError();
      },
    });
  }

  async getData() {
    this.currentUser = (await lastValueFrom(this.userService.getCurrentUser())) as User;
    this.company = this.currentUser.company;
    this.role = this.currentUser.role;
  }

  openModifyModal() {
    this.dialogService.showDialog(ModifyUserModalComponent, { user: { ...this.currentUser } }, async () => {
      await this.getData();
    });
  }

  openChangePasswordModal() {
    this.dialogService.showDialog(ChangePasswordModalComponent, undefined, async () => {
      await this.getData();
    });
  }

  tabChanged(event) {
    this.tabIndex = event.index;
    this.location.go('/oma/' + this.tabIndex);
  }

  updateSettings() {
    this.userService.saveCurrentUserSettings(this.settings).subscribe();
  }
}
