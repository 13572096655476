import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../../models/user';
import { UserService } from '../../services/user.service';
import { PhoneNumberValidator } from '../../../../helpers/PhoneNumberValidator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';
import { ReplaySubject } from 'rxjs';
import { Company } from '../../../../models/company';
import { LoginService } from '../../../auth/services/login.service';
import { FilterByField } from '../../../../helpers/DropdownFilters';
import { Role } from '../../../../models/role';

@Component({
    selector: 'xkey-user-modal',
    templateUrl: './modify-user-modal.component.html',
    styleUrls: ['./modify-user-modal.component.scss'],
    standalone: false
})
export class ModifyUserModalComponent implements OnInit {
  form: FormGroup;

  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

  public roleCtrl: FormControl = new FormControl();
  public roleFilterCtrl: FormControl = new FormControl();
  public filteredRoles: ReplaySubject<Role[]> = new ReplaySubject<Role[]>(1);

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private snackService: SnackService,
    private loginService: LoginService,
    public dialogRef: MatDialogRef<ModifyUserModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      user: User;
      companies: Company[];
      roles: Role[];
    }
  ) {}

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.companyCtrl.setValue((this.data.user?.company as Company)?.id ?? this.loginService.getCompany());
    this.roleCtrl.setValue((this.data.user?.role as Role)?.id);

    this.form = this.formBuilder.group({
      firstName: new FormControl(this.data.user.firstName, Validators.required),
      lastName: new FormControl(this.data.user.lastName, Validators.required),
      email: [this.data.user.email, [Validators.required, Validators.email]],
      phone: [this.data.user.phone, [Validators.required, PhoneNumberValidator('FI')]],
      company: this.companyCtrl,
      role: this.roleCtrl,
    });

    if (!this.loginService.userHasPermissions('Invitation_Create')) {
      this.f.role.disable();
    }

    this.filteredCompanies.next(this.data.companies.slice());
    this.filteredRoles.next(this.data.roles.slice());

    this.companyFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredCompanies, this.data.companies, 'name', this.companyFilterCtrl.value?.toLowerCase());
    });

    this.roleFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredRoles, this.data.roles, 'name', this.roleFilterCtrl.value?.toLowerCase());
    });
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    this.data.user.firstName = this.form.get('firstName').value;
    this.data.user.lastName = this.form.get('lastName').value;
    this.data.user.email = this.f.email.value;
    this.data.user.phone = this.f.phone.value;
    this.data.user.companyId = this.f.company.value;
    this.data.user.roleId = this.f.role.value;

    this.saveUser(this.data.user);
  }

  saveUser(user: User) {
    if (user.id) {
      this.userService.updateUser(user).subscribe({
        next: (result) => {
          this.snackService.showMessage('user.userUpdated');
          this.dialogRef.close(result);
        },
        error: (error) => {
          if (error.status === 409) {
            this.snackService.showMessage('user.emailAlreadyInUse');
          } else {
            this.snackService.showGeneralError();
          }
        },
      });
    }
  }
}
