import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { PhoneNumberValidator } from '../../../../helpers/PhoneNumberValidator';
import { SnackService } from '../../../ui/services/snack.service';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'xkey-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    standalone: false
})
export class RegisterComponent implements OnInit {
  code: string;
  form: FormGroup;
  protected readonly environment = environment;

  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackService: SnackService
  ) {
    this.route.params.subscribe((params) => {
      this.code = params['code'];
    });
  }

  get f() {
    return this.form.controls;
  }

  async ngOnInit() {
    if (this.loginService.getToken()) {
      await this.router.navigate(['/etusivu']);
    }

    this.form = this.formBuilder.group({
      code: [{ value: this.code, disabled: true }, Validators.required],
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, PhoneNumberValidator('FI')]],
      password: ['', [Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*[\\W])(?=.*[0-9])(?=.*[a-z]).{8,128}$')]],
      passwordAgain: ['', Validators.required],
    });
  }

  register() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const values = Object.assign({}, this.form.value);

    if (values.password !== values.passwordAgain) {
      this.form.controls['passwordAgain'].setErrors({ noMatch: true });
      return;
    }

    delete values.passwordAgain;
    delete values.code;

    this.loginService.register(values, this.code).subscribe({
      next: () => {
        this.router.navigate(['/etusivu']).then(() => {
          this.snackService.showMessage('register.welcome');
        });
      },
      error: (error) => {
        console.error(error);
        this.showError(error.status);
      },
    });
  }

  private showError(status) {
    if (status === 403) {
      this.snackService.showMessage('register.codeAlreadyUsed');
    }

    if (status === 422) {
      this.snackService.showMessage('register.codeInvalid');
    }

    if (status === 409) {
      this.snackService.showMessage('register.emailAlreadyInUse');
    }
  }
}
