{
  "name": "xkey-frontend",
  "version": "2.3.33",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@almothafar/angular-signature-pad": "^6.0.0",
    "@angular/animations": "^19.0.6",
    "@angular/cdk": "^19.0.5",
    "@angular/common": "^19.0.6",
    "@angular/compiler": "^19.0.6",
    "@angular/core": "^19.0.6",
    "@angular/forms": "^19.0.6",
    "@angular/localize": "^19.0.6",
    "@angular/material": "^19.0.5",
    "@angular/material-moment-adapter": "^19.0.5",
    "@angular/platform-browser": "^19.0.6",
    "@angular/platform-browser-dynamic": "^19.0.6",
    "@angular/router": "^19.0.6",
    "@angular/service-worker": "^19.0.6",
    "@asymmetrik/ngx-leaflet": "^18.0.1",
    "@ngx-translate/core": "^16.0.4",
    "@ngx-translate/http-loader": "^16.0.1",
    "axios": "^1.7.9",
    "bootstrap": "^5.3.3",
    "cookieconsent": "^3.1.1",
    "core-js": "^3.40.0",
    "file-saver": "^2.0.5",
    "google-libphonenumber": "^3.2.40",
    "leaflet": "^1.9.4",
    "marked": "^15.0.6",
    "masonry-layout": "^4.2.2",
    "moment": "^2.30.1",
    "ngx-cookie": "^6.0.1",
    "ngx-cookieconsent": "^8.0.0",
    "ngx-file-drop": "^16.0.0",
    "ngx-markdown": "^19.0.0",
    "ngx-masonry": "^14.0.1",
    "ngx-mat-select-search": "^7.0.9",
    "rxjs": "^7.8.1",
    "showdown": "^2.1.0",
    "signature_pad": "^5.0.4",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular/build": "^19.0.7",
    "@angular/cli": "^19.0.7",
    "@angular/compiler-cli": "^19.0.6",
    "@angular/language-service": "^19.0.6",
    "@types/file-saver": "^2.0.7",
    "@types/google-libphonenumber": "^7.4.30",
    "@types/leaflet": "^1.9.16",
    "@types/node": "^22.10.5",
    "codelyzer": "^6.0.2",
    "prettier": "^3.4.2",
    "protractor": "~7.0.0",
    "sass": "^1.83.1",
    "ts-node": "^10.9.2",
    "tslint": "~6.1.3",
    "typescript": "~5.6.3"
  }
}
