import { Component, OnInit } from '@angular/core';
import { User } from '../../../../models/user';
import { UserService } from '../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../auth/services/login.service';
import { OpenersService } from '../../../ui/services/openers.service';
import { ConfirmModalComponent } from '../../../ui/components/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { DialogService } from '../../../ui/services/dialog.service';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { lastValueFrom } from 'rxjs';
import { Company } from '../../../../models/company';
import { CompanyService } from '../../../company/services/company.service';
import { ModifyUserModalComponent } from '../modify-user-modal/modify-user-modal.component';
import { RoleService } from '../../../control-panel/services/role.service';
import { Role } from '../../../../models/role';

@Component({
    selector: 'xkey-user-single',
    templateUrl: './user-single.component.html',
    styleUrls: ['./user-single.component.scss'],
    standalone: false
})
export class UserSingleComponent implements OnInit {
  selectedUser: User;
  tabIndex = 0;

  constructor(
    public loginService: LoginService,
    public openers: OpenersService,
    private userService: UserService,
    private companyService: CompanyService,
    private roleService: RoleService,
    private route: ActivatedRoute,
    private location: Location,
    private translate: TranslateService,
    private dialogService: DialogService,
    public handsetObserverService: HandsetObserverService
  ) {
    this.route.params.subscribe((params) => {
      this.setupComponent(params['id']);
    });
  }

  ngOnInit(): void {
    this.tabIndex = parseInt(this.route.snapshot.paramMap.get('tab'), 10);
  }

  setupComponent(id) {
    this.userService.getUser(id).subscribe((user: User) => {
      this.selectedUser = user;
    });
  }

  async openModifyModal() {
    const companies: Company[] = await lastValueFrom(this.companyService.getCompanies());
    const roles: Role[] = await lastValueFrom(this.roleService.getRoles());

    this.dialogService.showDialog(
      ModifyUserModalComponent,
      {
        user: { ...this.selectedUser },
        companies,
        roles: this.loginService.userHasPermissions('Super_Admin') ? roles : roles.filter((role) => role.canBeInvited),
      },
      () => {
        this.userService.getUser(this.selectedUser.id).subscribe((updatedUser: User) => {
          this.selectedUser = updatedUser;
        });
      }
    );
  }

  openDeleteModal(userId: number) {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-user', title: this.translate.instant('user.deleteUser') }, () => {
      this.userService.deleteUser(userId).subscribe(() => {
        this.location.back();
      });
    });
  }

  tabChanged(event) {
    this.tabIndex = event.index;
    this.location.go('/kayttajat/' + this.selectedUser.id + '/' + this.tabIndex);
  }
}
