import { Component, DoCheck, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { OverlayService } from '../../services/overlay.service';

@Component({
    selector: 'xkey-progress-spinner',
    templateUrl: './progress-spinner.component.html',
    standalone: false
})
export class ProgressSpinnerComponent implements OnInit, DoCheck {
  @Input() displayProgressSpinner: boolean;

  @ViewChild('progressSpinnerRef')
  private progressSpinnerRef: TemplateRef<any>;
  private progressSpinnerOverlayConfig: any;
  private overlayRef: OverlayRef;

  constructor(
    private vcRef: ViewContainerRef,
    private overlayService: OverlayService
  ) {}

  ngOnInit() {
    this.progressSpinnerOverlayConfig = {
      hasBackdrop: true,
      positionStrategy: this.overlayService.positionGloballyCenter(),
    };

    this.overlayRef = this.overlayService.createOverlay(this.progressSpinnerOverlayConfig);
  }

  ngDoCheck() {
    if (this.displayProgressSpinner && !this.overlayRef.hasAttached()) {
      this.overlayService.attachTemplatePortal(this.overlayRef, this.progressSpinnerRef, this.vcRef);
    } else if (!this.displayProgressSpinner && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
