import { ApplicationRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from './modules/auth/services/login.service';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { LoadingService } from './modules/ui/services/loading.service';
import { filter, lastValueFrom, Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie';
import { DynamicScriptLoaderService } from './modules/shared/services/dynamic-script-loader.service';
import { Customisations } from './models/customisations';
import { CustomisationsService } from './modules/customisations/services/customisations.service';

declare let gtag: Function;

@Component({
    selector: 'xkey-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  private statusChangeSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    public loadingService: LoadingService,
    public loginService: LoginService,
    private customisationsService: CustomisationsService,
    private router: Router,
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    public ccService: NgcCookieConsentService,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private cookieService: CookieService
  ) {}

  get isLoggedIn(): boolean {
    return !!this.loginService.getToken();
  }

  get isInLogin(): boolean {
    return this.router.url === '/login';
  }

  get isInRegister(): boolean {
    return this.router.url.indexOf('/register') === 0;
  }

  get isInForgotPassword(): boolean {
    return this.router.url.indexOf('/unohdin-salasanani') === 0;
  }

  get isInPrivacyPolicy(): boolean {
    return this.router.url.indexOf('/tietosuojaseloste') === 0;
  }

  async ngOnInit(): Promise<void> {
    this.translate.setDefaultLang('fi');
    this.translate.use('fi');

    lastValueFrom(this.customisationsService.getCustomisations()).then((customisations: Customisations) => {
      if (customisations) {
        document.documentElement.style.setProperty('--color-primary', customisations.xkeyPrimary);
        document.documentElement.style.setProperty('--color-background', customisations.backgroundColor);
        document.documentElement.style.setProperty('--color-announcements', customisations.colorAnnouncements);
        document.documentElement.style.setProperty('--color-companies', customisations.colorCompanies);
        document.documentElement.style.setProperty('--color-keys', customisations.colorKeys);
        document.documentElement.style.setProperty('--color-residents', customisations.colorResidents);
        document.documentElement.style.setProperty('--color-condominia', customisations.colorCondominia);
        document.documentElement.style.setProperty('--color-apartments', customisations.colorApartments);
        document.documentElement.style.setProperty('--color-control-panel', customisations.colorControlPanel);
        document.documentElement.style.setProperty('--color-users', customisations.colorUsers);
        document.documentElement.style.setProperty('--color-audit', customisations.colorAudit);
        environment.logo = customisations.logo;
      }
    });

    if (this.updates.isEnabled) {
      await this.checkForUpdates();
    }

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(() => {
      this.setUpAnalytics(window.location.pathname);
    });

    const consent = this.cookieService.get('cookieconsent_status');
    if (consent) {
      this.setUpAnalytics(window.location.pathname);
    }
  }

  setUpAnalytics(initialUrl?: string): void {
    if (environment.useAnalytics) {
      this.loadGoogleAnalytics().then(() => {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
          this.sendUrlToGoogleAnalytics(event.urlAfterRedirects);
        });

        if (initialUrl) {
          this.sendUrlToGoogleAnalytics(initialUrl);
        }
      });
    }
  }

  async loadGoogleAnalytics(): Promise<any> {
    await this.dynamicScriptLoader.load('gtag', 'datalayer');
  }

  sendUrlToGoogleAnalytics(url: string): void {
    gtag('config', environment.googleAnalyticsId, {
      page_title: url,
    });
  }

  private async checkForUpdates(): Promise<void> {
    const event = await this.updates.checkForUpdate();
    if (event) {
      await this.updates.activateUpdate();
      document.location.reload();
    }
  }
}
